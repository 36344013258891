(function () {
  "use strict";

  function handleMenuClose() {
    const navCheckbox = document.querySelector('.navigation__checkbox')
    const navButtons = document.querySelectorAll('.navigation__nav-body a')

    function closeMenu() {
      navCheckbox.checked = false
    }

    navButtons.forEach((btn) => btn.addEventListener('click', closeMenu))

  }

  handleMenuClose()
})();